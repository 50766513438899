import axios from './axios.service'

const method = 'contact'

class ContactService {
  async getAll(queryData) {
    try {
      const response = await axios.get(`/${method}`, {
        params: { ...queryData }
      })
      return response.data
    } catch (error) {
      return error
    }
  }

  async getContactInformation(id) {
    try {
      const response = await axios({
        method: 'get',
        url: `/${method}/${id}`
      })
      return response
    } catch (error) {
      console.log(error)
    }
  }

  async deleteContact(rowClicked) {
    try {
      const response = await axios.delete(`/${method}/${rowClicked.id}`)
      return response
    } catch (error) {
      return error
    }
  }

  async deleteContactLpgd(id, data) {
    try {
      const response = await axios.delete(`/lgpd/${method}/${id}`, {
        params: { ...data }
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateContact(id, data) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/${id}`,
        data: {
          ...data
        }
      })
      return response
    } catch (error) {
      return error.response
    }
  }

  async updateArrayContact(data) {
    try {
      const response = await axios({
        method: 'put',
        url: '/arrayupdate/contact',
        data: {
          ...data
        }
      })
      return response
    } catch (error) {
      return error.response
    }
  }
}

export default new ContactService()
