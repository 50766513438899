<template>
  <div class="mailbox">
    <!-- Remove modal -->
    <CModal
      :title="modal.title"
      color="danger"
      :centered="true"
      :show.sync="modal.show"
    >
      {{ modal.message }}
      <template #footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="() => (modal.show = false)"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="handleRemove(false)"
        >
          Remover
        </button>
      </template>
    </CModal>

    <!-- Mailbox -->
    <CCard>
      <h4 class="mx-4 my-3">Contatos</h4>
      <CCardBody class="p-0 mr-4">
        <CRow>
          <!-- Mailbox search and filter -->
          <CCol lg="3" class="mailbox-filter-container">
            <ContactsFilter
              :totals="badgesTotals"
              @select="selectedFilterEvent"
            />
          </CCol>

          <!-- Mailbox toolbar and messages -->
          <CCol lg="9" class="mailbox-messages-container">
            <Search value="" class="px-3" @search="updateSearch" />

            <template v-if="items.length > 0 || showItem">
              <div class="mailbox-toolbar px-3 pb-3">
                <ContactsActions
                  :items="items"
                  :selected-items="selectedItems"
                  :showing-item="showItem"
                  :select-all="selectAll"
                  :refreshing="refreshing"
                  @select-all="toggleSelectAll"
                  @mark-as-read="markAsRead"
                  @mark-as-unread="markAsUnread"
                  @bookmark="bookmark"
                  @remove-bookmark="removeBookmark"
                  @remove="showRemoveModal(null)"
                  @refresh="refresh"
                  @back="hideItemDetails"
                />
              </div>

              <!-- Mailbox messages -->
              <CListGroup class="mailbox-messages">
                <!-- Mailbox messages list -->
                <template v-if="!showItem">
                  <CListGroupItem
                    v-for="item in items"
                    :key="item.id"
                    :class="`flex-column align-items-start border-left-0 border-right-0 border-0 list ${
                      item.read ? 'read' : ''
                    }`"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <div class="d-flex align-items-start w-100">
                        <div class="mailbox-messages-actions d-flex">
                          <CInputCheckbox
                            v-c-tooltip="'Selecionar'"
                            :checked="findSelectedItem(item)"
                            :label="' '"
                            :custom="true"
                            :inline="true"
                            class="mr-2"
                            :name="`select-item-${item.id}`"
                            style="padding-left: 30px"
                            @update:checked="toggleSelectItem($event, item)"
                          />
                          <a
                            v-c-tooltip="'Favoritar'"
                            href="javascript:void(0)"
                            class="mr-3 text-secondary bookmark-action"
                            :class="{ 'text-warning': item.favorite }"
                            @click="toggleBookmark(item)"
                          >
                            <i
                              v-if="item.favorite"
                              class="fas fa-star align-text-bottom"
                              style="font-size: 15px"
                            ></i>
                            <i
                              v-else
                              class="far fa-star align-text-bottom"
                              style="font-size: 15px"
                            ></i>
                          </a>
                          <a
                            v-c-tooltip="'Remover'"
                            href="javascript:void(0)"
                            class="mr-3 text-secondary"
                            @click="showRemoveModal(item)"
                          >
                            <i
                              class="fas fa-trash-alt align-middle"
                              style="font-size: 15px"
                            ></i>
                          </a>
                        </div>
                        <div
                          class="mailbox-messages-content"
                          :class="{ read: item.read }"
                        >
                          <a
                            href="javascript:void(0)"
                            @click="showItemDetails(item)"
                          >
                            <h5 class="mb-0">
                              <b>{{ item.author }}</b>
                            </h5>
                            <div
                              class="
                                mb-3
                                d-flex
                                justify-content-between
                                flex-wrap
                                w-100
                                align-items-center align-items-lg-start
                              "
                            >
                              <p class="mb-0 mt-1">
                                <small style="font-size: 0.9em">{{
                                  item.email
                                }}</small>
                                <small> |</small>
                                <small style="font-size: 0.9em" class="ml-1">{{
                                  item.phone
                                }}</small>
                              </p>
                              <small style="font-size: 0.9em">
                                <b>{{
                                  moment(item.created_at).format('DD/MM/YYYY')
                                }}</b
                                >,
                                {{ moment(item.created_at).format('HH:mm') }}
                              </small>
                            </div>
                            <p class="mb-0">
                              {{ item.message | truncate(300) }}
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </CListGroupItem>

                  <Pagination
                    :page="query.page"
                    :per-page="query.perpage"
                    :pages="pagination.pages"
                    :total="pagination.total"
                    :items="pagination.items"
                    class="mt-4 mb-5 px-3"
                    @update:active-page="(page) => (query.page = page)"
                    @update:perpage="(perpage) => (query.perpage = perpage)"
                  />
                </template>

                <!-- Mailbox message detail -->
                <template v-else>
                  <CListGroupItem
                    class="
                      flex-column
                      align-items-start
                      border-left-0 border-right-0 border-0
                      not-list
                    "
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <div class="mailbox-messages-content">
                        <div class="d-flex align-items-center">
                          <h5 class="mb-0 mr-2">
                            <b>{{ showItem.name }}</b>
                          </h5>

                          <small class="mt-1" style="font-size: 0.9em">
                            <b>{{
                              moment(showItem.created_at).format('DD/MM/YYYY')
                            }}</b
                            >,
                            {{ moment(showItem.created_at).format('HH:mm') }}
                          </small>
                        </div>

                        <div class="mt-3 mailbox-messages-sender">
                          <p class="m-0">
                            <small style="font-size: 0.9em" class="mr-2"
                              ><b>IP: </b>{{ showItem.ip }}</small
                            >
                            <small> |</small>
                            <small style="font-size: 0.9em" class="mx-2"
                              ><b>Navegador: </b
                              >{{
                                showItem.browser === 'undefined - undefined'
                                  ? 'Indefinido'
                                  : showItem.browser
                              }}</small
                            >
                            <small> |</small>
                            <small style="font-size: 0.9em" class="mx-2"
                              ><b>SO: </b
                              >{{
                                showItem.os === 'undefined - undefined'
                                  ? 'Indefinido'
                                  : showItem.os
                              }}</small
                            >
                            <small> |</small>
                            <small style="font-size: 0.9em" class="mx-2"
                              ><b>Device: </b
                              >{{
                                showItem.mobile === 'undefined - undefined'
                                  ? 'Desktop'
                                  : showItem.mobile
                              }}</small
                            >
                          </p>
                        </div>

                        <div class="my-3 mailbox-messages-informations">
                          <h5 class="mb-3"><b>Informações</b></h5>
                          <p class="mb-1">
                            <b>E-mail:</b> {{ showItem.email }}
                          </p>
                          <p class="mb-1">
                            <b>Telefone:</b> {{ showItem.phone }}
                          </p>
                          <p>
                            <b>Termos aceitos em:</b>
                            {{
                              moment(showItem.created_at).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            }}
                          </p>
                        </div>

                        <div
                          class="mailbox-messages-message"
                          v-if="showItem.message"
                        >
                          <h5 class="mb-3"><b>Mensagem</b></h5>
                          <p>
                            {{ showItem.message }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <CButton
                      class="mb-3"
                      color="danger"
                      @click="showRemoveModal(item, true)"
                    >
                      Excluir Permanentemente
                    </CButton>
                  </CListGroupItem>
                </template>
              </CListGroup>
            </template>

            <!-- Mailbox not founded -->
            <template
              v-if="
                items.length === 0 && !showItem && !refreshing && selectedFilter
              "
            >
              <div class="d-inline-block w-100 p-3">
                <p class="mb-0 text-muted">
                  No momento sua guia <b>{{ selectedFilter.name }}</b> está
                  vázia, navegue em outras guias para encontrar novos e-mails.
                </p>
              </div>
            </template>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { findIndex } from 'lodash'
import moment from 'moment'
import Pagination from '@/components/ui/Pagination'
import Search from '@/components/ui/Search'
import ContactsFilter from './ContactsFilter'
import ContactsActions from './ContactsActions'

import ContactService from '@/services/contact.service'

export default {
  metaInfo: {
    title: 'Contatos',
    titleTemplate: ' %s - Manager - OW Interactive'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_contacts')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    Pagination,
    Search,
    ContactsFilter,
    ContactsActions
  },

  data() {
    return {
      items: [],
      query: {
        search: '',
        page: 1,
        perpage: localStorage.getItem('perPageItem')
          ? parseFloat(localStorage.getItem('perPageItem'))
          : 10,
        pagination: true,
        status: '',
        sorter: {
          asc: false,
          column: ''
        },
        filter: 'all'
      },
      pagination: {
        total: 0,
        pages: 0,
        items: 0
      },
      selectedFilter: null,
      selectAll: false,
      selectedItems: [],
      removeItem: null,
      modal: {
        show: false,
        title: '',
        message: ''
      },
      removeLgpd: false,
      refreshing: false,
      showItem: null,
      badgesTotals: {}
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.init()
    },

    query: {
      handler: 'fillItems',
      deep: true
    },

    badgesTotals: {
      handler() {
        this.$root.$emit('update:routes')
      },
      deep: true
    }
  },

  async created() {
    await this.init()
  },

  methods: {
    moment,

    async init() {
      const { id } = this.$route.params

      this.selectAll = false
      this.showItem = null
      this.selectedItems = []

      await this.fillItems()

      // Find message by ID on API
      if (id) {
        const contact = await ContactService.getContactInformation(id)

        if (contact.status !== 404) {
          await ContactService.updateContact(id, { read: true })
          contact.data.read = true

          const response = await ContactService.getAll(this.query)
          this.badgesTotals = response.totals

          this.showItem = contact.data
          this.selectedItems.push(contact.data)
        } else {
          this.$notify({
            group: 'notifications',
            text: 'Mensagem não encontrada!',
            type: 'error'
          })
          this.hideItemDetails()
        }
      }
    },

    async fillItems() {
      this.refreshing = true

      const response = await ContactService.getAll(this.query)
      const { totals } = response
      const { total, lastPage: pages, data } = response.contacts

      if (data) {
        this.pagination = {
          total,
          pages,
          items: data.length
        }

        const newItems = data.map((contact) => {
          return {
            id: contact.id,
            author: contact.name,
            email: contact.email,
            phone: contact.phone,
            message: contact.message,
            created_at: contact.created_at,
            favorite: contact.favorite,
            read: contact.read,
            attachments: []
          }
        })

        this.items = newItems
        this.badgesTotals = totals
        this.refreshing = false
      }
    },

    async updateBadgesTotals(id) {
      const response = await ContactService.getAll(this.query)
      const contact = await ContactService.getContactInformation(id)

      this.showItem = contact
      this.selectedItems.push(contact)

      this.badgesTotals = response.totals
    },

    async updateSearch(searchText) {
      this.query.search = searchText
      this.query.page = 1

      if (this.$route.path !== '/contacts') {
        this.$router.push({
          path: '/contacts'
        })
      }
    },

    async selectedFilterEvent(filter) {
      this.query.filter = filter.slug
      this.selectedFilter = filter
      this.query.page = 1

      await this.fillItems()

      this.hideItemDetails()
    },

    /**
     * Toggle select all items
     */
    toggleSelectAll(checked) {
      this.selectAll = checked

      if (this.selectAll) {
        this.items.map((item) => this.selectedItems.push(item))
      } else {
        this.selectedItems = []
      }
    },

    /**
     * Toggle selected item
     */
    toggleSelectItem(checked, item) {
      const index = findIndex(this.selectedItems, { id: item.id })

      if (checked && index === -1) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems.splice(index, 1)
      }
    },

    /**
     * Find selected message
     */
    findSelectedItem(item) {
      const index = findIndex(this.selectedItems, { id: item.id })
      return index !== -1
    },

    /**
     * Mark all selected messages as read
     */
    async markAsRead() {
      const ids = this.selectedItems.map((item) => item.id)

      const response = await ContactService.updateArrayContact({
        ids,
        read: true
      })

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          text: 'As mensagens foram marcadas como lidas com sucesso',
          type: 'success'
        })
      } else {
        this.$notify({
          group: 'notifications',
          text: 'Houve um erro ao marcar as mensagens como lidas',
          type: 'error'
        })
      }

      this.selectedItems = []
      this.selectAll = false

      const { id } = this.$route.params

      if (id) {
        await this.updateBadgesTotals(id)
      } else {
        await this.init()
      }
    },

    /**
     * Mark all selected messages as unread
     */
    async markAsUnread() {
      const ids = this.selectedItems.map((item) => item.id)

      const response = await ContactService.updateArrayContact({
        ids,
        read: false
      })

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          text: 'As mensagens foram marcadas como não lidas com sucesso',
          type: 'success'
        })
      } else {
        this.$notify({
          group: 'notifications',
          text: 'Houve um erro ao marcar as mensagens como não lidas',
          type: 'error'
        })
      }

      this.selectedItems = []
      this.selectAll = false

      const { id } = this.$route.params

      if (id) {
        await this.updateBadgesTotals(id)
      } else {
        await this.init()
      }
    },

    /**
     * Add all selected messages on bookmarks folder
     */
    async bookmark() {
      const ids = this.selectedItems.map((item) => item.id)

      const response = await ContactService.updateArrayContact({
        ids,
        favorite: true
      })

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          text: 'As mensagens foram adicionadas dos favoritos com sucesso',
          type: 'success'
        })
      } else {
        this.$notify({
          group: 'notifications',
          text: 'Houve um erro ao adicionar as mensagens dos favoritos',
          type: 'error'
        })
      }

      this.selectedItems = []
      this.selectAll = false

      const { id } = this.$route.params

      if (id) {
        await this.updateBadgesTotals(id)
      } else {
        await this.init()
      }
    },

    /**
     * Remove all selected messages from bookmarks folder
     */
    async removeBookmark() {
      const ids = this.selectedItems.map((item) => item.id)

      const response = await ContactService.updateArrayContact({
        ids,
        favorite: false
      })

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          text: 'As mensagens foram removidas dos favoritos com sucesso',
          type: 'success'
        })
      } else {
        this.$notify({
          group: 'notifications',
          text: 'Houve um erro ao remover as mensagens dos favoritos',
          type: 'error'
        })
      }

      this.selectedItems = []
      this.selectAll = false

      const { id } = this.$route.params

      if (id) {
        await this.updateBadgesTotals(id)
      } else {
        await this.init()
      }
    },

    // Toggle message bookmark
    async toggleBookmark(item) {
      const { id } = item

      const response = await ContactService.updateContact(id, {
        favorite: !item.favorite
      })

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          text: `A mensagem foi ${
            !item.favorite ? 'adicionada aos' : 'removida dos'
          } favoritos`,
          type: 'success'
        })
      } else {
        this.$notify({
          group: 'notifications',
          text: `Houve um erro ao ${
            !item.favorite ? 'adicionar aos' : 'removover dos'
          } a mensagem favoritos, por favor tente novamente`,
          type: 'error'
        })
      }

      await this.init()
    },

    /**
     * Refresh messages
     */
    async refresh() {
      this.selectAll = false

      await this.init()

      this.$notify({
        group: 'notifications',
        text: 'As mensagens foram atualizadas',
        type: 'success'
      })
    },

    /**
     * Close confirm modal
     */
    closeModal() {
      this.modal = {
        show: false,
        title: '',
        message: ''
      }
    },

    /**
     * Show confirm modal
     */
    showRemoveModal(item, lgpd) {
      if (lgpd) {
        this.removeLgpd = true
      }
      this.removeItem = item || this.showItem
      this.modal = {
        show: true,
        title:
          this.removeItem !== null
            ? 'Remover mensagem'
            : 'Remover mensagens selecionadas',
        message:
          this.removeItem !== null
            ? 'Ao confirmar essa ação a mensagem não poderá ser recuperada, tem certeza que desejar continuar?'
            : 'Ao confirmar essa ação as mensagens não poderão ser recuperadas, tem certeza que desejar continuar?'
      }
    },

    /**
     * Remove messages
     */
    async remove() {
      if (this.removeItem !== null) {
        // Remove one message
        const response = await ContactService.deleteContact(this.removeItem)

        if (response.status === 200) {
          this.$notify({
            group: 'notifications',
            text: 'A mensagem foi excluída com sucesso',
            type: 'success'
          })
        } else {
          this.$notify({
            group: 'notifications',
            text: 'Houve um erro ao excluir a mensagem',
            type: 'error'
          })
        }

        this.removeItem = null

        await this.init()

        if (this.showItem) {
          this.hideItemDetails()
        }

        this.closeModal()
      } else {
        // Remove all selected messages
        const ids = this.selectedItems.map((item) => item.id)

        const response = await ContactService.updateArrayContact({
          ids,
          deleted_at: true
        })

        if (response.status === 200) {
          this.$notify({
            group: 'notifications',
            text: 'As mensagens foram removidas com sucesso',
            type: 'success'
          })
        } else {
          this.$notify({
            group: 'notifications',
            text: 'Houve um erro ao remover as mensagens',
            type: 'error'
          })
        }

        this.selectedItems = []
        this.selectAll = false

        const { id } = this.$route.params

        if (id) {
          await this.updateBadgesTotals(id)
        } else {
          await this.init()
        }

        this.closeModal()
      }
    },

    /**
     * Show item details
     */
    async showItemDetails(item) {
      const { id } = item

      this.$router.push({
        path: `/contacts/${id}`
      })
    },

    /**
     * Hide item details
     */
    hideItemDetails() {
      if (this.$route.path !== '/contacts') {
        this.$router.push({
          path: '/contacts'
        })
      }
    },

    async handleRemove() {
      if (this.removeLgpd) {
        await this.deletedLgpd()
        this.removeLgpd = false
      } else {
        this.remove()
      }
    },

    async deletedLgpd() {
      const response = await ContactService.deleteContactLpgd(
        this.$route.params.id,
        { type: 'Contato', name: this.showItem.name }
      )

      if (response && response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: `O contato de <b>${this.showItem.name}</b> foi excluído conforme a LGPD`
        })
        setTimeout(() => {
          this.processing = false
          this.$router.push(
            this.$route.matched[this.$route.matched.length - 2].path
          )
        }, 500)
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Não é possivel excluir o contato'
        })
      }

      this.removeLgpd = false

      if (this.showItem) {
        this.hideItemDetails()
      }

      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
.list {
  background: #d2d0d024;
  border-left: 3px solid #666666 !important;
  border-bottom: 1px solid #d2d0d073 !important;
}

.mailbox {
  overflow: hidden;

  .mailbox-toolbar {
    width: 100%;
    display: inline-block;

    button {
      border: 0;
      box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
        0 2px 1px -1px rgba(60, 75, 100, 0.12),
        0 1px 3px 0 rgba(60, 75, 100, 0.2) !important;
      padding: 0.375rem 0.75rem !important;

      i {
        margin: 0px;
        width: 100%;
      }
    }

    .mailbox-toolbar-select {
      padding: 0.455em 0.35em !important;

      .custom-checkbox {
        margin: 0px;
        padding: 0px 0px 0px 30px;
        display: inline-block;
      }
    }

    .refreshing {
      animation: spin 1.5s linear infinite;
    }

    @keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .mailbox-messages {
    height: 100%;

    .list-group-item {
      padding: 1rem 1.5rem 1rem 1rem;
      border-radius: 0px !important;

      &:hover,
      &.active,
      &:active,
      &:focus {
        background-color: rgba(249, 236, 133, 0.2) !important;
        color: inherit;
      }

      &.read {
        border-left: 3px solid #66666659 !important;
        background: none;
      }

      &.not-list {
        &:hover,
        &.active,
        &:active,
        &:focus {
          background-color: transparent !important;
          color: inherit;
        }
      }

      .mailbox-messages-content {
        padding-bottom: 1rem;
        width: 100%;

        &.read {
          opacity: 0.5;
        }

        h5 {
          color: $gray-900;
        }

        small {
          color: $gray-600;
        }

        p {
          color: $gray-800;
        }

        .attachments {
          ul {
            li {
              &:last-child {
                margin-bottom: 0px !important;
              }
            }
          }
        }
      }
    }

    .mailbox-messages-sender {
      border-top: 1px solid $gray-100;
      border-bottom: 1px solid $gray-100;
      padding: 1em 0;
      display: inline-block;
      width: 100%;
    }

    .mailbox-messages-informations {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid $gray-100;
      padding-bottom: 0.5em;
    }
  }

  .mailbox-filter-container {
    padding-right: 30px;
    margin-left: -5px;
  }

  .mailbox-messages-container {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .mailbox {
    .mailbox-messages-actions {
      flex-direction: column;
      align-items: center;

      .custom-checkbox {
        padding-left: 1.5rem !important;
        margin-bottom: 2em;
      }

      a {
        margin-bottom: 2em;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .mailbox {
    .mailbox-filter-container {
      padding-right: 15px;
    }

    .mailbox-messages-container {
      padding-left: 15px;
    }
  }
}
</style>
