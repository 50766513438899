<template>
  <CListGroup class="mailbox-filter">
    <CListGroupItem
      v-for="filter in filters"
      :key="filter.slug"
      class="d-flex align-items-center"
      href="javascript:void(0)"
      :active="selectedFilter.slug === filter.slug"
      @click="selectFilter(filter)"
    >
      <div class="d-flex align-items-baseline">
        <i class="mr-2 ml-3" :class="filter.icon"></i>
        <span class="mailbox-filter-name legend" v-html="filter.name"></span>
      </div>
    </CListGroupItem>
  </CListGroup>
</template>

<script>
import { findIndex } from 'lodash'

export default {
  props: {
    totals: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedFilter: null
    }
  },

  computed: {
    filters() {
      const { totalContacts, totalUnread, totalFavorite } = this.totals
      const totalRead = totalContacts - totalUnread
      const filters = [
        {
          name: `Todos ${totalContacts ? '(' + totalContacts + ')' : ''}`,
          icon: 'fas fa-inbox',
          slug: 'all'
        },

        {
          name: totalUnread
            ? `<b>Não Lidos ${'(' + totalUnread + ')'}</b>`
            : 'Não Lidos',
          icon: 'fas fa-envelope',
          slug: 'unread'
        },
        {
          name: `Lidos ${totalRead ? '(' + totalRead + ')' : ''}`,
          icon: 'fas fa-envelope-open',
          slug: 'read'
        },
        {
          name: `Favoritos ${totalFavorite ? '(' + totalFavorite + ')' : ''}`,
          icon: 'fas fa-star',
          slug: 'bookmarks'
        }
      ]

      return filters
    }
  },

  created() {
    this.filterAll()
  },

  methods: {
    /**
     * Filter all items
     */
    filterAll() {
      const index = findIndex(this.filters, { slug: 'all' })
      this.selectedFilter = this.filters[index]
    },

    /**
     * Current filter select event
     */
    selectFilter(filter) {
      this.selectedFilter = filter
      this.$emit('select', this.selectedFilter)
    }
  }
}
</script>

<style lang="scss">
.legend {
  min-width: 250px;
}

@media only screen and (max-width: 1800px) {
  .legend {
    min-width: 180px;
  }
}
@media only screen and (max-width: 1500px) {
  .legend {
    min-width: 130px;
  }
}
@media only screen and (max-width: 1300px) {
  .legend {
    min-width: 90px;
  }
}
@media only screen and (max-width: 1150px) {
  .legend {
    min-width: 50px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 1000px) {
  .legend {
    min-width: 250px;
  }
}

@media only screen and (max-width: 400px) {
  .legend {
    min-width: 150px;
  }
}

.mailbox-filter {
  border-right: 1px solid rgba(0, 0, 21, 0.125);
  height: 100%;

  .list-group-item {
    border: 0px;
    padding: 1rem 1.5rem 1rem 1rem;
    border-radius: 0px !important;

    .mailbox-filter-name {
      color: $gray-base;
    }

    &:first-child {
      padding: 1rem 0.8rem 1rem 0.8rem;

      &:hover {
        background-color: inherit;
      }
    }

    &:active,
    &:hover {
      background-color: $gray-100;
      color: inherit;
    }

    &.active {
      background-color: $gray-200 !important;
      color: inherit !important;

      .mailbox-filter-name {
        color: inherit !important;
      }
    }

    .badge {
      padding: 0.2rem 0.5rem;
    }

    &.mailbox-filter-search {
      #search {
        height: 35px;
        font-size: 12px;

        &::placeholder {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .mailbox-filter {
    border-right: 0px;
  }
}
</style>
